.primary-header-wrap {
  background-color: var(--color--grey--100);
  padding: var(--space--fixed--lg) 0;
  margin-bottom: var(--space--fixed--lg);
}

.primary-header__link {
  display: inline-block;
}

.primary-header__image {
  margin-right: var(--space--fixed--lg);
  max-height: 80px;
  vertical-align: top;
  width: auto;
}

.primary-header__button {
  background-color: var(--color--green--500);
  border-radius: var(--settings--border-radius--md);
  display: inline-block;
  color: var(--color--white);
  font-weight: var(--font--primary--semibold);
  padding: var(--space--fixed--xs) var(--space--fixed--xl);
  text-decoration: none;
}

.primary-header__button:hover,
.primary-header__button:focus {
  background-color: var(--color--green--700);
  color: var(--color--white);
}

.primary-header__button:active {
  background-color: var(--color--green--800);
  color: var(--color--white);
}

.primary-header__microcopy {
  margin-right: var(--space--fixed--md);
  font-size: var(--text--sm);
}
