@media screen and (min-width: 64em) {
  .footer-primary-navigation {
    text-align: right;
  }

}

.footer-primary-navigation-list {
  list-style: none;
  margin: 0;
}

.footer-primary-navigation-list__item {
  display: inline-block;
}

.footer-primary-navigation-list__link {
  padding: 0 var(--space--fixed--md);
  color: var(--color--white);
  text-decoration: none;
}

.footer-primary-navigation-list__link:hover,
.footer-primary-navigation-list__link:focus {
  color: var(--color--blue-light--500);
}

.footer-primary-navigation-list__link:active {
  color: var(--color--blue-light--100);
}

